<template>
  <div class="page-container">
    <breadcrumbs :bottom-margin="true" />
    <section class="services">
      <content-section
        v-for="(service, linkId) in services"
        :key="`service-${linkId}`"
        :id="`service-${linkId}`"
        :bgImage="service?.Theme === 'backgroundImage'"
        :isDark="service?.Theme === 'dark'"
        :isObserve="true"
        @inview="currentSection = `service-${linkId}`"
      >
        <h3
          class="animation"
          v-observe-visibility="{ callback: animation, once: true}"
          v-html="service?.Name"
        ></h3>
        <div
          class="markdown-content animation"
          v-observe-visibility="{ callback: animation, once: true}"
          v-html="markdownToHTML(service?.Description || '')"
        >
        </div>
        <template #background>
          <bg-image
            v-if="service?.Theme === 'backgroundImage'"
            :noGradient="true"
          >
            <picture v-if="service?.PictureOrVideo?.mime.includes('image')">
              <source
                v-if="service?.PictureOrVideoMobile"
                :srcset="getUrl(service?.PictureOrVideoMobile?.url)"
                :type="service?.PictureOrVideoMobile?.mime"
                media="(max-width: 767px)"
              />
              <source
                v-if="service?.PictureOrVideo"
                :srcset="getUrl(service?.PictureOrVideo?.url)"
                :type="service?.PictureOrVideo?.mime"
              />
              <img
                :src="getUrl(service?.PictureOrVideo?.url)"
                :alt="service?.Name"
              >
            </picture>
            <video
              v-if="service?.PictureOrVideo?.mime?.includes('video')"
              :src="getUrl(service?.PictureOrVideo.url)"
              loop
              autoplay
              muted
              playsinline
            >
              <source
                v-if="breakpoint==='sm'"
                media="(min-width: 767px)"
                :srcset="getUrl(service?.PictureOrVideoMobile?.url)"
              >
            </video>
          </bg-image>
        </template>
        <template #side>
          <div
            class="image animaton"
            v-if="service?.Theme === 'light' || service?.Theme === 'dark'"
            v-observe-visibility="{ callback: animation, once: true}"
          >
            <bg-image :noGradient="true">
              <picture v-if="service?.PictureOrVideo?.mime.includes('image')">
                <source
                  v-if="service?.PictureOrVideoMobile"
                  :srcset="getUrl(service?.PictureOrVideoMobile?.url)"
                  :type="service?.PictureOrVideoMobile?.mime"
                  media="(max-width: 767px)"
                />
                <source
                  v-if="service?.PictureOrVideo"
                  :srcset="getUrl(service?.PictureOrVideo?.url)"
                  :type="service?.PictureOrVideo?.mime"
                />
                <img
                  :src="getUrl(service?.PictureOrVideo?.url)"
                  :alt="service?.Name"
                >
              </picture>
              <video
                v-if="service?.PictureOrVideo?.mime?.includes('video')"
                :src="getUrl(service?.PictureOrVideo.url)"
                loop
                autoplay
                muted
                playsinline
              >
                <source
                  v-if="breakpoint==='sm'"
                  media="(min-width: 767px)"
                  :srcset="getUrl(service?.PictureOrVideoMobile?.url)"
                >
              </video>
            </bg-image>
          </div>
        </template>
      </content-section>
      <side-navigation
        :navigationList="services"
        :currentActive="currentSection"
      />
    </section>
  </div>
</template>

<script>
/* eslint-disable global-require */
/* eslint-disable import/no-dynamic-require */
import Breadcrumbs from '../components/Breadcrumbs.vue';
import ContentSection from '../components/ContentSection.vue';
import BgImage from '../components/BgImage.vue';
import SideNavigation from '../components/SideNavigation.vue';
import { animationMixin } from '@/components/mixins/animation';

// API
import api from '@/tools/api';
import getUrl from '@/tools/getUrl';

const md = require('markdown-it')({
  html: true,
  linkify: true,
  typographer: true,
});

export default {
  name: 'Services',
  mixins: [animationMixin],
  components: {
    Breadcrumbs,
    ContentSection,
    BgImage,
    SideNavigation,
  },
  data() {
    return {
      currentSection: undefined,
      pageContent: {},
    };
  },
  computed: {
    services() {
      return this.pageContent?.Sections;
    },
    currentLang() {
      return this.$store.getters.getLang;
    },
  },
  methods: {
    async getPageContent() {
      this.pageContent = await api('page-services-content', true);
    },
    markdownToHTML(content) {
      return md.render(content);
    },
    getUrl,
  },
  watch: {
    currentLang() {
      this.getPageContent();
    },
  },
  beforeMount() {
    this.getPageContent();
  },
};
</script>

<style lang="scss" scoped>
.breadcrumbs {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
}

.services {
  position: relative;

  ::v-deep(.content-section:first-child) {
    .main {
      @include screen("sm", max) {
        padding-top: pxtovw(330, sm);
      }
    }
  }
}
</style>
