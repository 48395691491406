<template>
  <section
    class="content-section"
    :class="{'is-bg': bgImage, 'is-dark': isDark}"
  >
    <slot name="background"></slot>
    <div class="content">
      <div class="main">
        <slot></slot>
      </div>
      <div
        class="side"
        v-if="!bgImage"
      >
        <slot name="side"></slot>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: 'ContentSection',
  data() {
    return {
      observer: null,
    };
  },
  emits: ['inview'],
  props: {
    bgImage: {
      type: [Boolean, Object],
      default: false,
    },
    isDark: {
      type: Boolean,
      default: false,
    },
    isObserve: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    sectionInView(enteries) {
      enteries.forEach((entry) => {
        if (entry.isIntersecting && entry.intersectionRatio > 0.5) {
          this.$emit('inview');
        }
      });
    },
    setObserver() {
      const observer = new IntersectionObserver(this.sectionInView, {
        threshold: [0, 0.25, 0.5, 0.75, 1],
      });
      observer.observe(this.$el);
      this.observer = observer;
    },
    removeObserver() {
      this.observer.unobserve(this.$el);
    },
  },
  mounted() {
    if (this.isObserve) {
      this.setObserver();
    }
  },
  unmounted() {
    if (this.observer) {
      this.removeObserver();
    }
  },
};
</script>

<style lang="scss" scoped>
.content-section {
  $b: &;
  position: relative;
  display: flex;
  justify-content: flex-start;
  width: 100%;

  @include screen("lg", min) {
    min-height: 100vh;
  }

  ::v-deep(.bg-image) {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: block;
    overflow: hidden;
    z-index: 1;
  }

  .content {
    position: relative;
    display: flex;
    z-index: 2;
    width: 100%;

    @include screen("md", max) {
      flex-wrap: wrap;
    }

    @include screen("xxl", min) {
      justify-content: space-between;
    }
  }

  .main {
    display: block;
    box-sizing: border-box;

    @include wRule(
      width,
      (
        xl: 1092,
        lg: 806,
        md: 1024,
      )
    );

    @include wRule(
      padding,
      (
        xl: 276 65 178 463,
        lg: 212 45 186 320,
        md: 167 135 74 304,
        sm: 111 45 105,
      )
    );

    @include screen("xxl", min) {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
      padding-top: 276px;
      padding-bottom: 276px;
    }

    ::v-deep(> *:first-child) {
      &.animation {
        opacity: 0;
        transform: translate3d(0, -20px, 0);
        transition: opacity 0.3s ease, transform 0.3s ease;
      }

      &.is-animated {
        opacity: 1;
        transform: translate3d(0, 0, 0);
        transition: opacity 0.5s ease 0.5s, transform 0.5s ease 0.5s;
      }
    }

    ::v-deep(.markdown-content > *) {
      @for $i from 1 to 20 {
        &:nth-child(#{$i}) {
          transition-delay: #{0.6s + 0.1s * ($i - 1)} !important;
        }
      }
    }

    ::v-deep(.markdown-content) {
      &.animation {
        > * {
          opacity: 0;
          transform: translate3d(0, -20px, 0);
          transition: opacity 0.3s ease, transform 0.3s ease;
        }
      }

      &.is-animated {
        > * {
          opacity: 1;
          transform: translate3d(0, 0, 0);
          transition: opacity 0.5s ease, transform 0.5s ease;
        }
      }
    }

    ::v-deep(h1) {
      display: block;
      font-weight: 700;
      letter-spacing: -0.02em;
      line-height: 1.2;
      width: 100%;

      @include wRule(
        font-size,
        (
          xl: 85,
          lg: 75,
          md: 55,
          sm: 75,
        )
      );
    }

    ::v-deep(h2) {
      display: block;
      font-weight: 700;
      letter-spacing: -0.02em;
      line-height: 1.2;
      width: 100%;

      @include wRule(
        font-size,
        (
          xl: 65,
          lg: 50,
          md: 45,
          sm: 65,
        )
      );
    }

    ::v-deep(h3) {
      display: block;
      font-weight: 700;
      letter-spacing: -0.02em;
      line-height: 1.2;

      @include wRule(
        font-size,
        (
          xl: 45,
          lg: 35,
          md: 30,
          sm: 45,
        )
      );

      &:not(:last-child) {
        @include wRule(
          margin-bottom,
          (
            xl: 70,
            lg: 60,
            md: 44,
            sm: 64,
          )
        );
      }
    }

    ::v-deep(p) {
      display: block;
      letter-spacing: -0.02em;
      font-weight: 400;
      line-height: 1.6;
      width: 100%;

      @include wRule(
        font-size,
        (
          xl: 20,
          lg: 18,
          md: 16,
          sm: 30,
        )
      );
    }

    ::v-deep(ul) {
      display: block;
      letter-spacing: -0.02em;
      font-weight: 400;
      line-height: 1.6;
      width: 100%;

      @include wRule(
        font-size,
        (
          xl: 20,
          lg: 18,
          md: 16,
          sm: 30,
        )
      );

      li {
        position: relative;
        display: block;
        box-sizing: border-box;

        @include wRule(
          padding-left,
          (
            xl: 72,
            lg: 54,
            md: 38,
            sm: 75,
          )
        );

        &::before {
          position: absolute;
          top: 0;
          left: 0;
          display: block;
          content: "";
          background: currentColor;
          margin-top: 0.7em;

          @include wRule(
            width,
            (
              xl: 20,
              lg: 15,
              md: 11,
              sm: 20,
            )
          );

          @include wRule(
            height,
            (
              xl: 3,
              lg: 2,
              md: 2,
              sm: 3,
            )
          );
        }

        &:not(:last-child) {
          @include wRule(
            margin-bottom,
            (
              xl: 16,
              lg: 15,
              md: 12,
              sm: 24,
            )
          );
        }
      }
    }
  }

  .side {
    position: relative;
    display: block;
    flex: 1;
    box-sizing: border-box;

    @include wRule(
      padding,
      (
        xl: 284 0 178,
        lg: 218 0 186,
        md: 0 0 92 304,
        sm: 0 0 120,
      )
    );

    @include screen("md", max) {
      flex: none;
      width: 100%;
    }

    @include screen("xxl", min) {
      padding-left: pxtovw(147, xl);
    }
  }

  ::v-deep(.image) {
    position: relative;
    display: block;
    overflow: hidden;
    width: 100%;

    @include ratio(828, 535);

    @include screen("lg", max) {
      @include ratio(560, 380);
    }

    @include screen("md", max) {
      @include ratio(720, 335);
    }

    @include screen("sm", max) {
      @include ratio(828, 535);
    }

    &.animation {
      opacity: 0;
      transform: translate3d(20px, 0, 0);
      transition: opacity 0.3s ease, transform 0.3s ease;
    }

    &.is-animated {
      opacity: 1;
      transform: translate3d(0, 0, 0);
      transition: opacity 0.5s ease 0.8s, transform 0.5s ease 0.8s;
    }

    .bg-image {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
  }

  &.is-bg {
    background-color: color(dark);
    color: color(basic-light);

    .main {
      background: rgba(0, 0, 0, 0.48);
      backdrop-filter: blur(11px);

      @include wRule(
        padding-bottom,
        (
          xl: 178,
          lg: 186,
          md: 168,
          sm: 105,
        )
      );

      @include screen("md", max) {
        backdrop-filter: none;
      }

      @include screen("xxl", min) {
        padding-bottom: 276px;
      }

      // @media screen and (min-width: 2000px) {
      //   display: flex;
      //   flex-direction: column;
      //   justify-content: center;
      //   align-items: flex-start;
      //   padding-top: 276px;
      //   padding-bottom: 276px;
      // }
    }
  }

  &.is-dark {
    background-color: color(dark);
    color: color(basic-light);

    ::v-deep(ul) {
      li {
        &::before {
          background-color: color(main);
        }
      }
    }
  }
}
</style>
