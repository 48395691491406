<template>
  <ul
    class="breadcrumbs animation"
    :class="{ 'has-bottom-margin': bottomMargin, 'is-dark': isDark }"
    v-observe-visibility="animation"
  >
    <li
      v-for="(item, index) in pathList"
      :key="index"
    >
      <component
        :is="item.link ? 'router-link' : 'span'"
        :to="item?.link"
      >{{ item.name }}</component>
    </li>
  </ul>
</template>

<script>
import { animationMixin } from '@/components/mixins/animation';

export default {
  name: 'Breadcrumbs',
  mixins: [animationMixin],
  computed: {
    lang() {
      return this.$store.getters.getLang;
    },
    pathList() {
      return [
        {
          link: '/',
          name: this.navigationData?.NamePageMain,
        },
        {
          name: this.navigationData?.[`NamePage${this.$route.name}`],
        },
      ];
    },
    navigationData() {
      return this.$store.getters.getNavigationData;
    },
    navColors() {
      return this.$store.getters.getNavigationColors;
    },
    isDark() {
      return this.navColors[this.$route.name];
    },
  },
  props: {
    bottomMargin: {
      type: Boolean,
    },
  },
};
</script>

<style lang="scss" scoped>
.breadcrumbs {
  justify-content: flex-start;
  align-items: center;
  list-style-type: none;
  margin: 0;
  width: 100%;
  box-sizing: border-box;
  color: color(basic-light);
  transition: color $ease-main;

  &.is-dark {
    color: color(basic);
  }

  &.has-bottom-margin {
    @include wRule(
      margin-bottom,
      (
        xl: 90,
        lg: 78,
        md: 50,
      )
    );
  }

  @include rule(
    display,
    (
      xl: flex,
      sm: none,
    )
  );
  @include wRule(
    padding,
    (
      xl: 170 104 0,
      lg: 121 74 0,
      md: 110 55 0,
      sm: 0 44,
    )
  );
  @include wRule(
    font-size,
    (
      xl: 16,
      lg: 14,
      md: 12,
      sm: 12,
    )
  );

  li {
    display: inline-flex;
    justify-content: flex-start;
    align-items: center;
    box-sizing: border-box;

    &:not(:last-child) {
      @include wRule(
        margin-right,
        (
          xl: 12,
          lg: 19,
          md: 16,
          sm: 16,
        )
      );

      &::after {
        display: block;
        content: "";
        background-repeat: no-repeat;
        background-position: 50% 50%;
        background-size: contain;
        background-image: url("data:image/svg+xml,%3Csvg width='12' height='8' viewBox='0 0 12 8' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M7.21875 7.53967L10.7403 4.01813' stroke='%23999999'/%3E%3Cpath d='M10.7402 4.52155L7.21869 1.00001' stroke='%23999999'/%3E%3Cpath d='M9.96042 4.27002L0 4.27002' stroke='%23999999'/%3E%3C/svg%3E%0A");

        @include wRule(
          width,
          (
            xl: 16,
            lg: 12,
            md: 10,
            sm: 10,
          )
        );
        @include wRule(
          height,
          (
            xl: 16,
            lg: 12,
            md: 10,
            sm: 10,
          )
        );
        @include wRule(
          margin-left,
          (
            xl: 12,
            lg: 22,
            md: 4,
            sm: 4,
          )
        );
      }
    }
  }

  span {
    display: inline-block;
    white-space: nowrap;
    cursor: default;
  }

  a {
    position: relative;
    display: inline-block;
    white-space: nowrap;
    cursor: pointer;

    &::before {
      @include pseudo;

      font-size: inherit;
      bottom: -0.04em;
      width: 100%;
      height: 0.1em;
      background-color: currentColor;
      transition: transform 0.4s ease;
      transform-origin: 0 50%;
      transform: scaleX(0);
    }

    &:hover {
      &::before {
        transform: scaleX(1);
      }
    }
  }

  &.animation {
    opacity: 0;
    transform: translate3d(0, -20px, 0);
    transition: opacity 0s ease, transform 0s ease;
  }

  &.is-animated {
    opacity: 1;
    transform: translate3d(0, 0, 0);
    transition: opacity 0.3s ease 0.5s, transform 0.3s ease 0.5s;
  }
}
</style>
